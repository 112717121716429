@import url('https://fonts.googleapis.com/css2?family=Poltawski+Nowy&family=Poppins&family=Roboto&family=Roboto+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poltawski+Nowy&family=Poppins&family=Roboto&display=swap');

/* ---------Default CSS------------*/
/* .Ram{
    display: none !important;
}
.Ram1:hover + .Ram{
    display: block;
} */


.flex-col{
    flex-direction: column;
}

.star{
    color: #ffd700;
    cursor: pointer;
}

.star::before{
    color: #ffd700;
}
.star-2{
    color: #ffd700;
    cursor: pointer;
}

.star-2::before{
    color: #ffd700;
}

.review-height{
    height: 348px;
    overflow: scroll;
}

.star-btn::before{
    color:#ffd700;
}

.product-conatiner{
    display: flex;
      flex-direction: column;
      row-gap: 20px;
  }

  .filter-content-section li{
    cursor: pointer;
    width: fit-content;
    background: #f9cf1c;
    color: white;
    padding: 10px;
    border-radius: 2px;
}
.filter-content-section li:hover{
    color: black;
}
  
  .dropdown{
    display: flex;
    column-gap: 20px;
  }


.d-flex{
    display: flex;
}

.g-gap{
    column-gap: 20px;
}

body{
    font-family: 'Roboto', sans-serif; 
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}



.card{
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

/* .pos-rel{
    position: relative;
} */

h6{
    text-align: initial;
}

h2{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 50px 0 0 0 ;
}

button{
    background-color: #329910;
}

.pos-rel{
    position: relative;
}







/* -------Header component Css---------- */


/*   Search css    */



.search_section {
    padding: 15px 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}




.search_input {
    flex: 1;
    padding: 0 20px;
    font-size: 20px;
    background-color: transparent;
}

.search_input_div {
    background-color: white;
    border-radius: 9px;
    /* width: 500px; */
    min-height: 39px;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search_result {
    background: grey;
    /* width: 400px; */
    
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 4;
}

.search_result::-webkit-scrollbar {
    display: none;
}

.search_suggestion_line,
.search_loading {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    display: block;
}

.search_suggestion_line:hover {
    background: rgb(227, 227, 227);
}

.search_icon {
    /* background-color: rgb(227, 227, 227); */
    padding: 0 25px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-cross-icon{
    height: 30px !important;
    cursor: pointer;
    padding-right: 10px;
}


.categories div{
    cursor: pointer;
}

.categories a{
    color: black !important;
}

.account-cart-container a{
    color: black !important;
}

.header-container{
    padding: 15px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9cf1c;
}

.categories-container hr{
    background-color: #480000;
}

.header-container img{
    height: 71px;
}

.product-list-container{
    display: flex;
    justify-content: center;
}

.user-img{
    
    display: none !important;
}

.icon{
    height: 25px !important;
}

.categories-container{
    position: relative;
}

.categories-container hr{
    margin: 0;
}

.dropdown-product-list{
    position: absolute;
 
    z-index: 3;
    visibility: hidden;
    background: white;
    top: 44px;
    margin-top: 20px;
    padding: 10px 15px;
    border-top: 0;
    transition: margin 400ms;
    color: rgb(182, 182, 182);
    font-family: 'Roboto', sans-serif;
    font-size: 15x;    
}

.dropdown-product-list ul li{
    margin-bottom: 15px;
}

.dropdown-product-list ul li{
    color: rgb(126, 126, 126) !important;
}

.y-gap:hover{
    color: grey;
}

.y-gap:hover .dropdown-product-list{
  margin-top: 10px;  
}

.dropdown-product-list li:hover{
    color: black !important; 
    /* font-size: 32px; */
}



.dropdown-product-list ul li:hover{
    color: rgb(187, 187, 187);
}

.y-gap{
    height: 100%;
    display: flex;
    align-items: center;
}

.hamburger-container{
    height: 20px;
    width: 30px;
    display: none;
    cursor: pointer;
}

.search-hamburger-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.hamburger-container .line{
    height: 3px;
    width: 95%;
    background-color: black;
    margin-bottom: 5px;
}

.cross{
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    display: none;
}


.header-container ul{
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.search_input_div_input{
    border-radius: 25px;
    padding: 7px 20px;
    display: flex;
    align-items: center;
}

/* .search-input img{
    height: 20px;
} */

.header-container input{
 border: none;
}

#cart{
    display: flex;
    align-items: center;
    column-gap: 8px;
}

#cart img{
    height: 15px;
}

.categories{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    column-gap: 40px;
    background-color: #f7fafe;
    height: 50px;
}

.cross-to-search-bar{
    height: 15px !important;
    margin-left: 5px;
    display: none;
}

.down-arrow{
    height: 12px;
}

/* ---------Banner components style------------ */

.crousel-btn{
    background: transparent !important;
}

/*  ------- Shop By Age ------- */


.each-img-block{
    overflow:hidden;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.shop-by-age-container{
    text-align: center;
    background-image: url("../images/backgroundfor.jpg");
}


.gg{
    width: 100%;
    height: 1rem;
    background-color: rgb(245, 232, 120) !important;
    /* height: 6px;
	background-image: url("../images/separator.webp") repeat-x 0 0;
     border: 4; */
}

.w-fit-c{
    width: fit-content;
}


.product-detail-container{
    padding: 0;
    width: 80vw;
    margin: auto;
}



.separator-image{
    background-image: url('../images/separator.webp');
    height: 35px;
    background-size: contain;
    transform: scale(-1);
    margin: 20px 0;
}

.shop-by-age-container span{
   text-align: center;
font-family: 'Roboto Slab', serif;
font-style: italic;
}

.age-image-container img{
     height: 250px;
     cursor: pointer;
     transition: transform 1s;
}

.age-image-container img:hover{
    transform: scale(1.2);
}

.shop-by-category{
    overflow: hidden;
}

.categories-image{
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: transform 1s;
}
.categories-image:hover{
    transform: scale(1.2);
}

 
/* ---------------BestSeller components style-------------- */

.bestseller{
    text-align: center;
    position:relative;
}


.cross-quick-view{
    position: absolute;
    top: 5px;
    right: 20px;
    height: 20px;
    cursor: pointer;
}

.quick-view-product-container{
    position: fixed;
    top: 20%;
    left: 0;   
    z-index: 5;
    opacity: 0;
    height: 0;
    z-index: 9999;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.5s, height 0.5s, visibility 0.5s;
}

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    backdrop-filter: blur(5px);
  }

  .bestseller-col-lg-7{
    width: 45.333333% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .bestseller-col-lg-5{
    width:30.666667% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .quick-view-row{
    justify-content: center;
  }

.pt-5{
    padding: 15px 0 0 0 !important;
}

.bestseller-span{
text-align: center;
font-family: 'Roboto Slab', serif;
font-style: italic;
}


.bestseller h2{
    text-align: center;
}
.image-add-to-cart-conatiner{
    position: relative;
    overflow: hidden;
}

.add-to-cart-dropdown-container{
    position: absolute;
    top: 35%;
    left: 30%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: -60%;
    transition: margin 500ms;
}

.image-add-to-cart-conatiner{
    cursor: pointer;
    border-bottom: 1px solid #00000026;
    padding: 20px;
}

.py-10{
    padding: 0 10px;
}

.image-add-to-cart-conatiner:hover .add-to-cart-dropdown-container {
    margin-top: 0;
  }
.image-add-to-cart-conatiner:hover .img-fluid {
    transform: scale(1.2);
    opacity: 0.5;
  }

.quick-add-to-btn{
    padding: 5px 10px;
    background-color: white !important;
    border: none;
    color: black;
    border-radius: 10px;
}
.quick-add-to-btn:hover {
    color: white;
    background-color: black !important;
}

.img-fluid{
    height: 230px;
    width: 100%;
    transition: transform 0.5s ease;
}

.col-md-4{
    width: 25%;
}

.card-body{
    padding:0 0 10px 5px;
    text-align: initial;
    font-size: 14px;
}

.card-title{
    display: block;
    width: 100%;
    height: 40px;
    font-size: 15px;
    margin-bottom: 2px;
}

.add-to-cart-container button{
    background: green;
    border: none;
    width: 100%;
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 10px 0;
}

.star-group img{
    height: 20px;
}

.star-group{
    display: inline-flex;
    column-gap: 2px;
    justify-content: center;
}

.review-section{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.brand-type{
    display: grid;
    grid-template-columns: auto auto auto;
}

.price-section{
    display: flex;
    column-gap: 20px;
}

.current-price{
    font-weight: 700;
}
.previous-price{
    text-decoration: line-through;
    color: grey;
}

/* ------Blog style--------*/

.card-img-top{
    height: 230px;
}

.card-group{
    column-gap: 15px;
    margin-top: 50px;
}


/* ----------- Customer Components Style ------------- */

.customer-review-container{
    margin-top: 60px;
}
.total-review{
    text-align: center;
}

.review-content{
    display: flex;
    flex-direction: column;
}
.review-person{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-details span{
    color: rgb(84, 180, 211);
}

.review-title{
    font-weight: bold;
}

.product-img img{
    width: 100%;
}

.review-card-container{
    padding: 0 20px;
    width: 63vw;
    overflow: hidden;
    margin: auto;
}

.review-cards{
    display: flex;
    margin-top: 30px; 
   
    transition-duration: .5s;
    transition-timing-function: linear;
    
}

.custom-card{
   text-align: center;
   padding: 10px 0;
   display: flex;
   flex-direction: column;
   row-gap: 20px;
   min-width: 20vw;
}

.arrow-img img{
    width: 30px;
    cursor: pointer;
}

.arrow-container{
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 10px;
}


*{
    margin:0 ;
    padding: 0;
}

body {
  /* width: 100%;
  height: 100vh;
  margin: 0px; */
  overflow-x:hidden;
  background: #fdfdfd;
}




/* Category */


.shop-by-Category-container{
            display: flex;
            justify-content: space-around;
}   

.shop-by-Category-container div{
    min-height: 150px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /* background: white; */
    /* border-radius: 50%; */
    align-items: center;
    /* border: 1px solid #f1f1f1; */
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
}

.accessories{
    color: #e36ba2;
    background-image: url('../images/accessories-small.jpg') !important;
    border: 1px solid #e36ba2;
    background-size: cover !important;
    overflow: hidden;
    
}

.accessories:hover{
    background: linear-gradient(#e36ba2,#d8327c);
    color: white;
}
.toys{
    color: #5f4e85;
    border: 1px solid #5f4e85;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-image: url('../images/toys-small.webp') !important;
    background-size: cover !important;
    /* height: 200px ; */
}

.toys:hover{
    background: linear-gradient(#5f4e85,#331277);
    
    color: white;
}
.diapers{
    color: #7dbb44;
    background-image: url('../images/diaper-small.jpg') !important;
    border: 1px solid #7dbb44;
    background-size: cover !important;
}

.diapers:hover{
    background: linear-gradient(#7dbb44,#57951e);
    color: white;
}
.cot{
    color: #f3bc5b;
    background-image: url('../images/cots-small.webp') !important;
    border: 1px solid #f3bc5b;
    background-size: cover !important;
}

.cot:hover{
    background: linear-gradient(#f3bc5b,#b57d1c);
    color: white;
}
.jacket{
    color: #71dcc9;
    background-image: url('../images/jacket-small.jpg') !important;
    border: 1px solid #71dcc9 !important;
    background-size: cover !important;
}

.jacket:hover{
    background: linear-gradient(#71dcc9,#19a78f);
    color: white;
}
.stroller{
    color: #ed782f;
    border: 1px solid #ed782f !important;
    background-image: url('../images/stroller-small.avif') !important;
    background-size: cover !important;
}

.stroller:hover{
    background: linear-gradient(#ed782f,#ad5a28);
    color: white;
}

.Category-container {
    display: grid;
    grid-template-columns: 39vw 39vw;
    justify-content: space-between;
    row-gap: 20px;
    margin-top: 60px;
   
}
.Category-container img{
    width: 100%;
   
}


/* ReadMore component Style */

#read-text{
    padding: 10px 40px;
}

.About img{
    width:100%;
    height: 100%;
}
.About p{
    max-width:100vw;
    text-align:justify;
}


/* Newsletter */


.newsletter p{
    text-align: center;
}
  
  .column {
    float: left;
    width: 33.33%;
    padding: 5px;
  }
  
  /* Clearfix (clear floats) */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .product-image-section{
    width: 350px;
    height: 350px;
  }

  .product-image-section img{
    width: 100%;
    height: 100%;
  }

  .text-container{
    
    overflow: scroll;
    height: 100vh !important;
  }

  .column .input-group{
    height: 3rem;
    border-radius:5rem;
  }
  #search-button  {
    border-radius: 20rem;
    border: none !important;
    background-color: white !important;
   
  }
  .row .column img{
    height:20vh;
  
  }

  .product-dropdwon{
    left: 35% !important;
  }

  .w-100{
    width: 100vw !important;
  }
 
  .container .head{
    margin: 5rem 0 2rem 0;
  }

  .row .column .img2{
    position: absolute;
    right: 0;
  }
 

/* Press */

 .marquee {
  position: relative;
   height: 50px;
   margin-top: 20px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.track {
  display: inline-block;
  margin-left: 20rem;
  width: 100vw;
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 8s linear infinite;

}

@keyframes marquee {
  
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
  
} 





/* Footer */

footer{
  background-color: #f9cf1c;
  margin-top: 60px; 
}

.icon{
  display: flex;
 
}
.text-md-right{
justify-content: center;
  column-gap: 30px;

}

/* ------ Filter component Style ------*/


.filter-container h2{
    text-align: initial;
    margin: 20px 0;
    font-size: 18px;
}

.filter-content-section li{
    cursor: pointer;
}
.filter-content-section li:hover{
    color: black;
}

.stock-container{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}




.color-circle{
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.color-section{
    display: grid;
    grid-template-columns: 15px 15px 15px 15px 15px;
    column-gap: 19px;
    row-gap: 10px;
}


.price-box input{
    width: 30px;
}

.price-box div{
    display: flex;
    column-gap: 5px;
    margin-bottom: 10px;
}

.drop-down-arrow{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.text-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* ------ Product list component style -------- */


.product-item span{
    font-size: 20px;
}

.filter-text-container{
    display: none;
}

.filter-sign-container{
    width: 30px;
    height: 30px;
}

.sign-line{
    position: relative;
    width: 95%;
    height: 2px;
    margin-top: 8px;
    background-color: black;
}


.sign-line::before{
    position: absolute;
    content: '';
    border-radius: 50%;
    height: 7px;
    width: 7px;
    top: -2px;
    background: black;
    left: 46%;
}

.sign-line::after{
    position: absolute;
    content: '';
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: -1px;
    background: white;
    left: 52%;
}



.product{
    display: grid;
    grid-template-columns: 15vw auto;
    justify-content: space-around;
}

.Product-list-card-container .card{
    min-width: 20vw;
    margin-bottom: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.cart-btn{
    width: 100%;
}



.Product-list-card-container .card-body{
    padding: 12px 5px !important;
}

.container-available img{
    width: 32vw;
    display: inline-block;
    padding: 3rem;
}
 .text h1{
    text-align: center;
}

.container-filter a img{
    width: 100vw;
}

.text h2{
    font-size: medium;
    font-weight: 700;
    margin-left: 2rem;
}
h3{
    /* font-size: medium; */
    font-weight: 450;
    margin-left: 2rem;
}
a{
    text-decoration: none;
}


.Active{
    display: grid;
    grid-template-columns: 25vw 25vw 25vw; 
    column-gap: 20px; 
}

.offer-h2{
    color: white;
    background-color: red;
    position: absolute;
    left: 8px;
    top: 19px;
    width:20%;
    text-align: center;
    
}
.right-offer-h2{
    color: black;
    background-color: yellow;
    position: absolute;
    right: 8px;
    top: 19px;
    width: 16%;
    text-align: center;
}
 

.fa{
    color: #efcd49;
}
.text{
    margin-top: 2rem;
}
.text h2{
    font-weight: 400;
}
.card-text a{
    color: black;
}

.card-text{
    display: flex;
    font-size: 18px;
    font-weight: 600;
    flex-direction: column;
    row-gap: 4px;
}
.card-price{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}
.edit-submit-btn{
    padding: 10px 30px;
    background: #329910;
    color: white;
    border-radius: 5px;
}

.filter-content-section{
    display: grid;
    font-size: 20px;
    grid-gap: 15px;

}

.review-section{
    display: flex;
    flex-direction: row !important;
    column-gap: 17px;
}
.review-thumb{
    display: flex;
    flex-direction:column;
    column-gap: 17px;
}
/* --------- Login component Style -----------*/

.login-form form{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.login-form form label{
    font-weight: bold;
}

.login-form form input{
    height: 40px;
    border: 1px solid #dbdbdb;
    padding: 10px 10px;
}
.submit-btn-container a{
    color: blue !important;
}

.login-form{
    min-width: 30vw;
}

.login{
    display: flex;
    justify-content: center;
}

.submit-btn{
    width: 40%;
    height: 40px;
    background: #329910;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
}

.submit-btn-container{
display: flex;
align-items: center;
justify-content: space-between;
}

/* ----------- Parenting component style ----------*/

.blog-card img{
    
    width: 100%;
}
.blog-container{
    display: flex;
    justify-content: center;
    column-gap: 20px;
}
p{
    font-size: 14px;
    text-align: initial;
    
}
.blog-text{
    justify-content: center;
}

.blog-card{
   width: 25vw;
}

.blog-card h2{
    text-align: initial;
    margin: 0;
}

.blog-card h6{
    margin: 20px 0 40px 0
}

.tag-section{
    font-size: 13px;
}


/* --------User Cart components--------- */

.user-detail-conatiner{
    width: 50vw;
    margin-top: 70px;
}

.detail-section
{
 display: grid;
 grid-template-columns: 40% 40%;
 justify-content: space-around;
 border-radius: 8px;
 box-shadow: 0 0 18px rgba(24,39,75,.05);
 padding: 20px 0;
}

/* --------User Home components--------- */

.logout-btn{
    cursor:pointer;
    border-radius: 8px;
    padding: 10px 15px;
    color: white;
}


/* my  css */
.card-img{
    height: 300px !important;
}


.black{
    background-color: #f9cf1c;}


.font{
    font-size: 1.25rem;
}
.form{

    position: relative;
}

.form .fa-search{

    position: absolute;
    top:20px;
    left: 20px;
    color: #9ca3af;

}

.form span{

        position: absolute;
    right: 17px;
    top: 13px;
    padding: 2px;
    border-left: 1px solid #d1d5db;

}

.left-pan{
    padding-left: 7px;
}

.left-pan i{
   
   padding-left: 10px;
}

.form-input{

    height: 55px;
    text-indent: 33px;
    border-radius: 10px;
}

.form-input:focus{

    box-shadow: none;
    border:none;
}

.logonav{
    width: 80px;
    height: 50px;
    margin-top: -2px;
}

.nav-right{
    margin-top: 45px;
}




@media screen and (max-width: 568px) and (min-width:100px) {
  .leftsejao{
    margin-left: 90px;
  }


}


::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 10px;  
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:gray; 
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  .text-container::-webkit-scrollbar {
    width: 0;
  }
  
  .review-height::-webkit-scrollbar {
    width: 0;
  }

  .review-section{
    display: flex;
    flex-direction: column;
  }

  .review-width{
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid black;
  }

  body .text-container{
    overflow: scroll;
  }

  .cursor-p{
    cursor: pointer;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    /* border-radius: 10px; */
    border: none;
  }


  .footer_kid_store {
    background-position: -163px -88px;
    display: inline;
    height: 43px;
    margin-top: 10px;
    position: absolute;
    /* top: -40px; */
    right: 40px;
    width: 383px;
    z-index: 10;
}

  @import url('https://fonts.googleapis.com/css2?family=Caveat&family=Playfair+Display:ital,wght@0,500;0,600;0,700;1,400&display=swap');

.font_change  {
    font-family: 'Caveat', cursive;
    font-family: 'Playfair Display', serif;
    font-weight: 900 !important;
    font-size: 1.6rem;
    color: #e74c3c;
}


.tic{    background-color: #fff;
    border-radius: 30px;
    clear: both;
    height: 60px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    top: -60px;
    width: 470px;
    z-index: 3;
    box-shadow: 0 0 3px #e0e0e0;
}
/* my css end */
.cart-btn{
    background-color:#f9cf1c !important;
    color: white !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}